
import React,{ Component } from 'react';
import Index from './pages/index'
import './App.css';
import { NavLink, Routes, Route } from "react-router-dom";
import Product from "./pages/Product";
import About from "./pages/About";
import Support from "./pages/Support";
import Legal from "./pages/Legal";
import Contact from "./pages/Contact";
import {AuthContext, AuthContextType} from "./utils/AuthProvider";
import {useContext} from "react";
import Dashboard from "./pages/Dashboard";
import VirtualLab from "./pages/VirtualLab";
import Profile from "./pages/Profile";
import Subscriptions from "./pages/Subscriptions";
import Notifications from "./pages/Notifications";
import AtomicIndex from "./pages/atomic/index";
const useAuth = () => useContext(AuthContext);

export default function App() {
    const auth = useAuth();

    const { user, isLogin } = auth as AuthContextType;
    return (
       <Routes>
            <Route path="/" element={<Index />}/>
            <Route path="/about" element={<About />} />
            <Route path="/support" element={<Support />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/contact" element={<Contact />}/>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/virtual_lab" element={<VirtualLab />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/atomic/index" element={<AtomicIndex />} />
        </Routes>
    );
}
