import React from "react";
import Header from "./components/Header";
import RegisterModel from "./components/RegisterModel";
import LoginModal from "./components/LoginModal";
import Footer from "./components/Footer";
import {Lang} from "../utils/common";
import {Form} from "react-bootstrap";

class Notifications extends React.Component {
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            locale: 'en-us',
            showLogin: false,
            showRegister: false,
            country:'+86',
            isFooterShow: false,
        }
    }

    componentDidMount(){
        let lang = localStorage.getItem('lang');
        if(lang){
            this.setState({
                ...this.state,
                locale: localStorage.getItem('lang')
            })
        }
    }

    setLocale(key: string){
        const state = this.state;
        localStorage.setItem('lang', key);
        this.setState({...state,locale: key});
    }
    handleLoginClose(){
        const state = this.state;
        this.setState({...state,showLogin: false});
    }
    handleRegisterClose(){
        const state = this.state;
        this.setState({...state,showRegister: false});
    }
    showLoginModel(){
        const state = this.state;
        this.setState({...state,showLogin: true});
    }

    showRegisterModel(){
        const state = this.state;
        this.setState({...state,showLogin: false, showRegister: true});
    }
    setCountry(value: string){
        const state = this.state;
        this.setState({...state, country:value});
    }

    render() {
        // @ts-ignore
        let { showLogin, locale, showRegister, country } = this.state;

        return (<div className='main-context'>
            <Header
                locale={locale}
                showLoginModel={() => this.showLoginModel()}
                setLocale={(key: string) => this.setLocale(key)}
            />
            <RegisterModel
                showRegister={showRegister}
                locale={locale}
                country={country}
                handleRegisterClose={()=>this.handleRegisterClose()}
                setCountry={(e: string)=>this.setCountry(e)}
            ></RegisterModel>
            <LoginModal
                showLogin={showLogin}
                locale={locale}
                handleLoginClose={()=>this.handleLoginClose()}
                showRegisterModel={()=>this.showRegisterModel()}
            />
            <section id="home" className='hero-section'>
                <div className='container'>
                    <h1>{Lang(locale).notification_title}</h1>
                    <div className={'row'}>
                        <div className={'col-lg-6 col-md-6'}>
                            <Form.Group className="mb-3" controlId="radio" >
                                <Form.Label>{Lang(locale).email_notification}</Form.Label>
                                <Form.Check
                                    value={'1'}
                                    type={'radio'}
                                    name={'email'}
                                    label={Lang(locale).on}
                                    id={'type-1'}
                                />
                                <Form.Check
                                    value={'0'}
                                    name={'email'}
                                    type={'radio'}
                                    label={Lang(locale).off}
                                    id={'type-0'}
                                />
                            </Form.Group>
                        </div>
                        <div className={'col-lg-6 col-md-6'}>
                            <Form.Group className="mb-3" controlId="radio" >
                                <Form.Label>{Lang(locale).new_product}</Form.Label>
                                <Form.Check
                                    value={'1'}
                                    type={'radio'}
                                    name={'product'}
                                    label={Lang(locale).on}
                                    id={'type-1'}
                                />
                                <Form.Check
                                    value={'0'}
                                    name={'product'}
                                    type={'radio'}
                                    label={Lang(locale).off}
                                    id={'type-0'}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>)
    }
}

export default Notifications;
