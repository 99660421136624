import Modal from "react-bootstrap/Modal";
import {Lang} from "../../utils/common";
import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";
import { toast } from 'react-toastify';
import { login } from "../../api/UserApi";
import {AuthContext, AuthContextType} from "../../utils/AuthProvider";
import {useContext} from "react";
const useAuth = () => useContext(AuthContext);
interface ILoginData {
    username: string,
    password: string
}

function LoginModal(props: any){
    let auth = useAuth();
    let { signIn } = auth as AuthContextType;
    let {showLogin, locale, handleLoginClose, showRegisterModel} = props;
    const [loginData, setLoginData] = useState<ILoginData>({
        'username':'',
        'password':'',
    });
    const handleSubmit = async (e: { preventDefault: () => void; } | undefined) => {
        // @ts-ignore
        e.preventDefault();
        if(loginData.username==''){
            toast(Lang(locale).username_empty_error);
            return;
        }
        if(loginData.password==''){
            toast(Lang(locale).password_empty_error);
            return;
        }


        login(loginData).then((res: any)=>{
            console.log(res);
            if(res.code===200){
                let token = res.data.token;
                let user = res.data.user;
                localStorage.setItem('userToken', token);
                localStorage.setItem('user', user);
                // @ts-ignore
                signIn(user, () =>{
                    //window.open('/dashboard');
                    window.location.href="/dashboard";
                });
                handleLoginClose();
            }
        }).catch((error: any)=>{

        });
    }
    return(<div>
        <Modal
            show={showLogin}
            onHide={()=>handleLoginClose()}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header onClick={()=>handleLoginClose()} closeButton>
                <h2>{Lang(locale).loginTitle}</h2>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e)=>handleSubmit(e)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label>{Lang(locale).email}</Form.Label>
                        <Form.Control type="text" onChange={e => setLoginData({...loginData, username: e.target.value})} placeholder={Lang(locale).emailPlaceholder}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>{Lang(locale).password}</Form.Label>
                        <Form.Control type="password" onChange={e => setLoginData({...loginData, password: e.target.value})} placeholder={Lang(locale).passwordPlaceholder} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button  className="btn btn-primary btn-lg" type="submit">
                            {Lang(locale).loginBtn}
                        </Button>
                    </div>
                    <div className="d-grid gap-2 pall tc mt-15 mb-15">
                        {Lang(locale).loginDesc}
                    </div>
                    <div className="d-grid gap-2 pall tc mt-15 mb-15">
                        <a href="#" onClick={()=>showRegisterModel()}>{Lang(locale).loginRegBtn}</a>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </div>)
}
export default LoginModal;
