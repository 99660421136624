export const lang = {
    'login': 'Login',
    'language':'Language',
    'cn':'Chinese',
    'en':'English',
    'welcome':'Even if alone in this world, the beauty of natural science won’t make you feel bored',
    'regTitle':'Create Account',
    'loginTitle':'Login to Oldtown',
    'email':'Email',
    'emailPlaceholder':'Enter email',
    'phone':'Phone',
    'phonePlaceholder':'Enter Phone',
    'user_num':'Registered users',
    'userNumPlaceholder': 'Total number of registered users',
    'userName': 'User Name',
    'userNamePlaceholder': 'Enter User Name',
    'password': 'Password',
    'passwordPlaceholder': 'Enter Password',
    'regBtn': 'Create account',
    'loginBtn': 'Login In',
    'loginDesc':'Can\'t log in to your account? Try another way',
    'loginRegBtn':'Create an account',
    'about_title':'About',
    'about_context':'Learn more about the team maintaining Old Town, how and why the project started, and how to get involved.',
    'support_title':'Support',
    'support_context':'Welcome to send an email to support@oldtown.ai',
    'legal_title':' Privacy & Legal',
    'legal_context':'Welcome to send an email to support@oldtown.ai',
    'contact_title':'Contact US',
    'contact_context':'Welcome to send an email to support@oldtown.ai',
    'nav_dashboard': 'Dashboard',
    'nav_virtual_lab':'Virtual lab',
    'nav_signOut':'signOut',
    'nav_profile':'My Profile',
    'tit_physics':'Physics',
    'tit_chemistry':'Chemistry',
    'tit_biology':'Biology',
    'notification_title':'Notification Settings',
    'email_notification':'Email Notification',
    'new_product':'Product New Feature Subscriptions Email',
    'on': 'On',
    'off': 'Off',
    'firstName': 'First Name',
    'firstNamePlaceholder':'Enter First Name',
    'lastName': 'Last Name',
    'lastNamePlaceholder':'Enter Last Name',
    'userTypeLabel':'I am a high school or college student',
    'userType0':'high-school student',
    'userType1':'college student',
    'birthLabel': 'Date of Birth',
    'btnLabel':'Submit',
    'password_length_error':'Password must be at least 6 digits!',
    'password_error':'Your password complexity is too low (it must contain uppercase and lowercase letters, numbers, and special characters). Please modify your password promptly!',
    'email_error':"Please enter the correct email address!",
    'phone_error':"Please enter the correct phone number!",
    'username_empty_error':"User name cannot be empty！",
    'password_empty_error':"Password cannot be empty！",
};
