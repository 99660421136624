import React from "react";
import './dashboard.css';
import NavDropdown from "react-bootstrap/NavDropdown";
import {Lang} from "../utils/common";
import {Accordion, ListGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {AuthContext} from "../utils/AuthProvider";


class Dashboard extends React.Component {
    static contextType = AuthContext;

    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            locale: 'en-us',
            showLogin: false,
            showRegister: false,
            country: '+86',
            isFooterShow: false,
            isHide:false,
            list: [],
        }
    }

    componentDidMount(){
        let width =  document.body.clientWidth;
        let lang = localStorage.getItem('lang');
        if(lang){
            this.setState({
                ...this.state,
                locale: localStorage.getItem('lang')
            })
        }
        console.log('width:'+width);
        if(width<992){
            setTimeout(()=>{
                this.setState({
                    ...this.state,
                    isHide: true
                })
            },0);

        }

        // @ts-ignore
        let list = {
            'zh-cn':{
                'physics':[
                    {title:'高一物理实验',desc:'显示实验列表，当前为空'},
                    {title:'高二物理实验',desc:'显示实验列表，当前为空'},
                    {title:'高三物理实验',desc:'显示实验列表，当前为空'},
                ],
                'chemistry':[
                    {title:'高一化学实验',desc:'显示实验列表，当前为空'},
                    {title:'高二化学实验',desc:'显示实验列表，当前为空'},
                    {title:'高三化学实验',desc:'显示实验列表，当前为空'},
                ],
                'biology':[
                    {title:'高一化学实验',desc:'显示实验列表，当前为空'},
                    {title:'高二化学实验',desc:'显示实验列表，当前为空'},
                    {title:'高三化学实验',desc:'显示实验列表，当前为空'},
                ],
            },
            'en-us': {
                'physics': [
                    {title: 'Physics Level 1 Experiments', desc: 'Display the list of experiments, currently empty'},
                    {title: 'Physics Level 2 Experiments', desc: 'Display the list of experiments, currently empty'},
                    {title: 'Physics Level 3 Experiments', desc: 'Display the list of experiments, currently empty'},
                ],
                'chemistry': [
                    {title: 'Chemistry Level 1 Experiments', desc: 'Display the list of experiments, currently empty'},
                    {title: 'Chemistry Level 2 Experiments', desc: 'Display the list of experiments, currently empty'},
                    {title: 'Chemistry Level 3 Experiments', desc: 'Display the list of experiments, currently empty'},
                ],
                'biology': [
                    {title: 'Biology Level 1 Experiments', desc: 'Display the list of experiments, currently empty'},
                    {title: 'Biology Level 2 Experiments', desc: 'Display the list of experiments, currently empty'},
                    {title: 'Biology Level 3 Experiments', desc: 'Display the list of experiments, currently empty'},
                ],
            }
        };
        this.setState({...this.state, list: list});
        console.log(this.context);
        // @ts-ignore
        let { isLogin } = this.context;
        if(isLogin===false) window.location.href="/";
    }

    setLocale(key: string){
        const state = this.state;
        localStorage.setItem('lang', key);
        this.setState({...state,locale: key});
    }
    outLogin(){

        let store =  this.context;
        console.log(store);
        // @ts-ignore
        store.signOut(()=>{
            localStorage.removeItem('userToken');
            localStorage.removeItem('user');
            window.location.href="/";
        });
    }

    goUrl(url: string){
        window.location.href= url;
    }

    setIsHide(){
        // @ts-ignore
        this.setState({...this.state, isHide: !this.state.isHide})
    }

    render() {
        // @ts-ignore
        let { locale, list, isHide} = this.state;

        return(<>
            <div className={isHide? 'app-wrapper hideSidebar':'app-wrapper'}>
                <div className={'sidebar-container'}>
                    <div className={'slide-wrapper'}>
                        <div className={'slide-top'}>

                        </div>
                        <div className={'slide-list'}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{Lang(locale).tit_physics}</Accordion.Header>
                                    <Accordion.Body>
                                       <ul>
                                            {list&&list[locale]&&list[locale]['physics'].map((item: any, key:number)=>{
                                                return (<li  key={key} className="d-flex justify-content-between align-items-start">{item.title}</li>);
                                            })}
                                       </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{Lang(locale).tit_chemistry}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {list&&list[locale]&&list[locale]['chemistry'].map((item: any, key:number)=>{
                                                return (<li  key={key} className="d-flex justify-content-between align-items-start">{item.title}</li>);
                                            })}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{Lang(locale).tit_biology}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {list&&list[locale]&&list[locale]['biology'].map((item: any, key:number)=>{
                                                return (<li  key={key} className="d-flex justify-content-between align-items-start">{item.title}</li>);
                                            })}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </div>
                        <div className={'slide-bottom'}>
                             <div>
                                 <button type={"button"} onClick={()=>this.goUrl('/dashboard')} className={'el-button'}><span>{Lang(locale).nav_dashboard}</span></button>
                             </div>
                            <div>
                                <button type={"button"} onClick={()=>this.goUrl('/virtual_lab')} className={'el-button'}><span>{Lang(locale).nav_virtual_lab}</span></button>
                            </div>
                            <div>
                                <button type={"button"} onClick={()=>this.goUrl('/profile')} className={'el-button'}><span>{Lang(locale).nav_profile}</span></button>
                            </div>
                            <div>
                                <button type={"button"} onClick={()=>this.outLogin()} className={'el-button'}><span>{Lang(locale).nav_signOut}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isHide? 'fixed-header mobile':'fixed-header'}>
                     <div className={'navbar'}>
                         <div onClick={()=>this.setIsHide()} className={'hamburger-container'}>
                             {isHide?(<svg data-v-49e15297="" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                  width="64" height="64" className="hamburger">
                                 <path data-v-49e15297=""
                                       d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"></path>
                             </svg>):
                                 (<svg data-v-49e15297="" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                       width="64" height="64" className="hamburger is-active">
                                     <path data-v-49e15297=""
                                           d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"></path>
                                 </svg>)}
                         </div>
                         <div className={'right-menu'}>

                             <div className="header-btn">
                                 <NavDropdown  title={Lang(locale).language} id="lang-dropdown">
                                     <NavDropdown.Item eventKey="cn" onClick={()=>this.setLocale("zh-cn")}>{Lang(locale).cn}</NavDropdown.Item>
                                     <NavDropdown.Item eventKey="en" onClick={()=>this.setLocale("en-us")}>{Lang(locale).en}</NavDropdown.Item>
                                 </NavDropdown>
                             </div>
                         </div>
                     </div>
                </div>
                <div className={isHide?'main-container mobile':'main-container'}>
                    <div className={'welcome'}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 mb20">
                                <div className="section-title text-center mb-60">
                                    <h3>{Lang(locale).tit_physics}</h3>
                                </div>
                                <ListGroup as="ol">
                                    {list&&list[locale]&&list[locale]['physics'].map((item: any, key:number)=>{
                                        return (<ListGroup.Item
                                            as="li"
                                            key={key}
                                            className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.title}</div>
                                                {item.desc}
                                            </div>
                                        </ListGroup.Item>);
                                    })}

                                </ListGroup>
                            </div>
                            <div className="col-lg-4 col-md-6 mb20">
                                <div className="section-title text-center mb-60">
                                    <h3>{Lang(locale).tit_chemistry}</h3>
                                </div>
                                <ListGroup as="ol">
                                    {list&&list[locale]&&list[locale]['chemistry'].map((item: any, key:number)=>{
                                        return (<ListGroup.Item
                                            as="li"
                                            key={key}
                                            className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.title}</div>
                                                {item.desc}
                                            </div>
                                        </ListGroup.Item>);
                                    })}
                                </ListGroup>
                            </div>
                            <div className="col-lg-4 col-md-6 mb20">
                                <div className="section-title text-center mb-60">
                                    <h3>{Lang(locale).tit_biology}</h3>
                                </div>
                                <ListGroup as="ol">
                                    {list&&list[locale]&&list[locale]['biology'].map((item: any, key:number)=>{
                                        return (<ListGroup.Item
                                            as="li"
                                            key={key}
                                            className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.title}</div>
                                                {item.desc}
                                            </div>
                                        </ListGroup.Item>);
                                    })}

                                </ListGroup>
                            </div>
                        </div>
                    </div>
                    <div className={'copyright'}>
                        <span>OLD AI</span>
                    </div>

                </div>
            </div>
        </>)
    }
}
export default Dashboard
