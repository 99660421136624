import React from "react";
import Header from "./components/Header";
import {Lang} from "../utils/common";

import Footer from "./components/Footer";
import './about.css';
import RegisterModel from "./components/RegisterModel";
import LoginModal from "./components/LoginModal";

class Legal extends React.Component{
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            locale: 'en-us',
            showLogin: false,
            showRegister: false,
            country:'+86',
            isFooterShow: false,
        }
    }
    componentDidMount(){
        let lang = localStorage.getItem('lang');
        if(lang){
            this.setState({
                ...this.state,
                locale: localStorage.getItem('lang')
            })
        }
    }

    setLocale(key: string){
        const state = this.state;
        localStorage.setItem('lang', key);
        this.setState({...state,locale: key});
    }
    handleLoginClose(){
        const state = this.state;
        this.setState({...state,showLogin: false});
    }
    handleRegisterClose(){
        const state = this.state;
        this.setState({...state,showRegister: false});
    }
    showLoginModel(){
        const state = this.state;
        this.setState({...state,showLogin: true});
    }

    showRegisterModel(){
        const state = this.state;
        this.setState({...state,showLogin: false, showRegister: true});
    }
    setCountry(value: string){
        const state = this.state;
        this.setState({...state, country:value});
    }
    render() {
        // @ts-ignore
        let {list, showLogin, locale, showRegister, country, isFooterShow} = this.state;

        return (
            <div className='main-context'>
                <Header
                    locale={locale}
                    showLoginModel={() => this.showLoginModel()}
                    setLocale={(key: string) => this.setLocale(key)}
                />
                <RegisterModel
                    showRegister={showRegister}
                    locale={locale}
                    country={country}
                    handleRegisterClose={()=>this.handleRegisterClose()}
                    setCountry={(e: string)=>this.setCountry(e)}
                ></RegisterModel>
                <LoginModal
                    showLogin={showLogin}
                    locale={locale}
                    handleLoginClose={()=>this.handleLoginClose()}
                    showRegisterModel={()=>this.showRegisterModel()}
                />
                <section id="home" className='hero-section'>
                    <div className='container'>
                        <h1>{Lang(locale).legal_title}</h1>
                        <div>
                            <p className='bd-lead'>
                                {Lang(locale).legal_context}
                            </p>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>)
    }
}
export default Legal;
