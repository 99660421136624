import React from "react";
import Header from "./components/Header";
import RegisterModel from "./components/RegisterModel";
import LoginModal from "./components/LoginModal";
import Footer from "./components/Footer";
import {Button, Form} from "react-bootstrap";
import {Lang} from "../utils/common";
import Dropdown from "react-bootstrap/Dropdown";

class Profile extends React.Component {
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            locale: 'en-us',
            showLogin: false,
            showRegister: false,
            country:'+86',
            isFooterShow: false,
        }
    }

    componentDidMount(){
        let lang = localStorage.getItem('lang');
        if(lang){
            this.setState({
                ...this.state,
                locale: localStorage.getItem('lang')
            })
        }
    }

    setLocale(key: string){
        const state = this.state;
        localStorage.setItem('lang', key);
        this.setState({...state,locale: key});
    }
    handleLoginClose(){
        const state = this.state;
        this.setState({...state,showLogin: false});
    }
    handleRegisterClose(){
        const state = this.state;
        this.setState({...state,showRegister: false});
    }
    showLoginModel(){
        const state = this.state;
        this.setState({...state,showLogin: true});
    }

    showRegisterModel(){
        const state = this.state;
        this.setState({...state,showLogin: false, showRegister: true});
    }
    setCountry(value: string){
        const state = this.state;
        this.setState({...state, country:value});
    }

    async handleSubmit(e: { preventDefault: () => void; } | undefined){

    }


    render() {
        // @ts-ignore
        let { showLogin, locale, showRegister, country } = this.state;

        return (<div className='main-context'>
            <Header
                locale={locale}
                showLoginModel={() => this.showLoginModel()}
                setLocale={(key: string) => this.setLocale(key)}
            />
            <RegisterModel
                showRegister={showRegister}
                locale={locale}
                country={country}
                handleRegisterClose={()=>this.handleRegisterClose()}
                setCountry={(e: string)=>this.setCountry(e)}
            ></RegisterModel>
            <LoginModal
                showLogin={showLogin}
                locale={locale}
                handleLoginClose={()=>this.handleLoginClose()}
                showRegisterModel={()=>this.showRegisterModel()}
            />
            <section id="home" className='hero-section'>
                <div className='container' style={{paddingTop:'150px;'}}>
                    <div className="row justify-content-center">
                        <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-11">
                            <div className="section-title text-center mb-60">
                                <h2>My Profile</h2>
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={(e)=>this.handleSubmit(e)}>
                    <div className='row'>
                        <div className="col-lg-6 col-md-6">
                            <Form.Group className="mb-3" controlId="First Name" >
                                <Form.Label>{Lang(locale).firstName}</Form.Label>
                                <Form.Control type="text"  placeholder={Lang(locale).firstNamePlaceholder}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Last Name" >
                                <Form.Label>{Lang(locale).lastName}</Form.Label>
                                <Form.Control type="text"  placeholder={Lang(locale).lastNamePlaceholder}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail" >
                                <Form.Label>{Lang(locale).email}</Form.Label>
                                <Form.Control type="text"   placeholder={Lang(locale).emailPlaceholder}/>
                            </Form.Group>

                        </div>
                        <div className="col-lg-6 col-md-6">
                            <Form.Group className="mb-3" controlId="formBasicPhone" >
                                <Form.Label>{Lang(locale).phone}</Form.Label>
                                <div className="input-group mb-3">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            {country}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>this.setCountry('+86')}>+86</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>this.setCountry('+1')}>+1</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control type="phone"  placeholder={Lang(locale).phonePlaceholder} />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPhone" >
                                <Form.Label>{Lang(locale).userTypeLabel}</Form.Label>
                                <div className={'row'}>
                                    <div className="col-lg-4 col-md-6">
                                        <Form.Check
                                            value={'0'}
                                            type={'radio'}
                                            name={'userType'}
                                            label={Lang(locale).userType0}
                                            id={'type-0'}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <Form.Check
                                            value={'1'}
                                            name={'userType'}
                                            type={'radio'}
                                            label={Lang(locale).userType1}
                                            id={'type-1'}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Date of Birth" >
                                <Form.Label>{Lang(locale).birthLabel}</Form.Label>
                                <Form.Control type="date"  placeholder={Lang(locale).birthLabel}/>
                            </Form.Group>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="d-grid gap-2">
                            <Button  className="btn btn-primary" type="submit">
                                {Lang(locale).btnLabel}
                            </Button>
                        </div>
                    </div>
                    </Form>
                </div>
            </section>

            <Footer />
        </div>)
    }
}

export default Profile;
