import Modal from "react-bootstrap/Modal";
import {Lang} from "../../utils/common";
import {Button, Form} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import React, {useState} from "react";
import { register } from "../../api/UserApi";
import { toast } from 'react-toastify';
import {AuthContext, AuthContextType} from "../../utils/AuthProvider";
import {useContext} from "react";
const useAuth = () => useContext(AuthContext);
interface IUserData {
    email: string,
    phone: string,
    user_num: number,
    user_name: string,
    password: string,
    country: string

}
function RegisterModel(props: any){
    let auth = useAuth();
    let { signIn } = auth as AuthContextType;
    let {showRegister, locale, country, handleRegisterClose,setCountry} = props;
    const [formData, setFormData] = useState<IUserData>({
        email:'',
        phone:'',
        user_num:1,
        user_name:'',
        password:'',
        country:''
    });

    const handleCountry = (country: string)=>{
        setFormData({...formData, country: country});
        setCountry(country);
    }



    const handleSubmit = async (e: { preventDefault: () => void; } | undefined) => {
        // @ts-ignore
        e.preventDefault();
        console.log(formData);
        if(formData['password'].length<6) {
            toast(Lang(locale).password_length_error);
            return;
        }
        let pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
        // @ts-ignore
        if (!pwdRegex.test(formData['password'])) {
            toast(Lang(locale).password_error);
            return;
        }
        let reg = /^([0-9a-zA-Z_\.\-\])+\@([0-9a-zA-Z_\.\-\])+\.([a-zA-Z]+)$/;

        if(!reg.test(formData['email'])){
            toast(Lang(locale).email_error);
            return;
        }

        if(formData['phone']==''){
            toast(Lang(locale).phone_error);
            return;
        }
        register(formData).then((res: any)=>{
            console.log(res)
            if(res.code===200){
                let token = res.data.token;
                let user = res.data.user;
                localStorage.setItem('userToken', token);
                localStorage.setItem('user', user);
                toast(res.msg);
               setTimeout(()=>{
                    signIn(user, ()=>{
                        window.location.href="/dashboard";
                    });
                }, 300);
                handleRegisterClose();
            }else{
                toast(res.msg);
            }
        }).catch((error: any)=>{
            console.log(error)
        })
    };
    return(<div>
        <Modal
            show={showRegister}
            onHide={()=>handleRegisterClose()}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header onClick={()=>handleRegisterClose()} closeButton>
                <h2>{Lang(locale).regTitle}</h2>
            </Modal.Header>
            <Modal.Body>
                <Form  onSubmit={(e)=>handleSubmit(e)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{Lang(locale).email}</Form.Label>
                        <Form.Control type="email"  onChange={e => setFormData({...formData, email: e.target.value})}  placeholder={Lang(locale).emailPlaceholder} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                        <Form.Label>{Lang(locale).phone}</Form.Label>
                        <div className="input-group mb-3">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    {country}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={()=>handleCountry('+86')}>+86</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleCountry('+1')}>+1</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control type="phone"   onChange={e => setFormData({...formData, phone: e.target.value})} placeholder={Lang(locale).phonePlaceholder} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{Lang(locale).user_num}</Form.Label>
                        <Form.Control type="user_num"   onChange={e => setFormData({...formData, user_num: Number(e.target.value)})} placeholder={Lang(locale).userNumPlaceholder} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicUserName">
                        <Form.Label>{Lang(locale).username}</Form.Label>
                        <Form.Control type="user_name"   onChange={e => setFormData({...formData, user_name: e.target.value})} placeholder={Lang(locale).userNamePlaceholder} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>{Lang(locale).password}</Form.Label>
                        <Form.Control type="password"  onChange={e => setFormData({...formData, password: e.target.value})} placeholder={Lang(locale).passwordPlaceholder} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button  className="btn btn-primary btn-lg" type="submit">
                            {Lang(locale).regBtn}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
    </div>)

}
export default RegisterModel;
