import request from '../utils/request';

function register(params: any){
    return  request('post','v1/login/register', params);
}

function login(params: any){
    return request('post', 'v1/login/login', params);
}

export {
    register,
    login
}
