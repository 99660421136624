// @ts-ignore
import { SlideContainer, SlidePage } from 'react-slidepage';
import React, {useContext} from "react";
import './index.css';

import Header from "./components/Header";
import Footer from "./components/Footer";
import { Lang } from "../utils/common";

import LoginModal from "./components/LoginModal";
import RegisterModel from "./components/RegisterModel";
require('react-slidepage/lib/index.css');

class Index extends React.Component {
    constructor(props: {} | Readonly<{}>) {
        super(props);

        this.state = {
            list: [
                {name: 'Page1', class: 'page1'},
                {name: 'Page2', class: 'page2'}
            ],
            locale:  'en-us',
            showLogin: false,
            showRegister: false,
            country:'+86',
            isFooterShow: false,
        }

    }
    componentDidMount(){
        let lang = localStorage.getItem('lang');
        if(lang){
            this.setState({
                ...this.state,
                locale: localStorage.getItem('lang')
            })
        }

    }
    setLocale(key: string){
        const state = this.state;
        localStorage.setItem('lang', key);
        this.setState({...state,locale: key});
    }
    handleLoginClose(){
        const state = this.state;
        this.setState({...state,showLogin: false});
    }
    handleRegisterClose(){
        const state = this.state;
        this.setState({...state,showRegister: false});
    }
    showLoginModel(){
        const state = this.state;
        this.setState({...state,showLogin: true});
    }
    SlidePageBefore(origin: number, direction: any, target: number){
        //console.log(target);
        const state = this.state;
        // @ts-ignore
        if(target == state.list.length) this.setState({...this.state, isFooterShow: true});
        else { // @ts-ignore
            if(state.isFooterShow) this.setState({...this.state, isFooterShow: false});
        }
    }
    showRegisterModel(){
        const state = this.state;
        this.setState({...state,showLogin: false, showRegister: true});
    }
    setCountry(value: string){
        const state = this.state;
        this.setState({...state, country:value});
    }
    render() {
        // @ts-ignore
        let {list, showLogin, locale, showRegister, country, isFooterShow} = this.state;

        return (
            <div className='main-context'>
                <Header
                    locale={locale}
                    showLoginModel={()=>this.showLoginModel()}
                    setLocale={(key: string)=>this.setLocale(key)}
                    isIndex={true}
                />
                <RegisterModel
                    showRegister={showRegister}
                    locale={locale}
                    country={country}
                    handleRegisterClose={()=>this.handleRegisterClose()}
                    setCountry={(e: string)=>this.setCountry(e)}
                ></RegisterModel>
                <LoginModal
                    showLogin={showLogin}
                    locale={locale}
                    handleLoginClose={()=>this.handleLoginClose()}
                    showRegisterModel={()=>this.showRegisterModel()}
                />

                <SlideContainer useSwipe={true}  useWheel={true} useAnimation={true} before={(origin: number, direction: any, target: number)=>this.SlidePageBefore(origin, direction, target)}>
                    {
                        list.map((item: any, index: number) => {
                            return (
                                <SlidePage key={index} className={item.class}>
                                    {index==0&&<div className='welcome'>
                                        {/* eslint-disable-next-line react/jsx-no-undef */}
                                        <h1>{Lang(locale).welcome}</h1>
                                    </div>}

                                </SlidePage>
                            )
                        })
                    }
                </SlideContainer>
                {isFooterShow&&<Footer
                    locale={locale}
                />}
            </div>)
    }
}
export default  Index;
