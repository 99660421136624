import { ReactNode, createContext, useState} from "react";

export interface AuthContextType{
    user: any,
    isLogin: boolean,
    signIn: (user: string, callback: VoidFunction)=>void;
    signOut:(callback: VoidFunction)=>void;
}

export let AuthContext = createContext<AuthContextType|null>(null);

const fakeAuthProvider = {
    isAuthenticated: false,
    signIn(callback: VoidFunction){
        this.isAuthenticated = true;
        setTimeout(callback, 100);
    },
    signOut(callback: VoidFunction){
        this.isAuthenticated = false;
        setTimeout(callback, 100)
    }
}

const AuthProvider = ({ children }: { children: ReactNode }) =>{
    const userInfo  = localStorage.getItem('user');
    const [user, setUser] = useState<any>(userInfo);
    const [isLogin, setIsLogin] = useState<boolean>(userInfo?true:false);
    let signIn = (loginUser: any, callback: VoidFunction) =>{
        return fakeAuthProvider.signIn(()=>{
           setUser(loginUser);
           setIsLogin(true);
           callback();
        });
    };

    let signOut = (callback: VoidFunction)=>{
        return fakeAuthProvider.signOut(()=>{
              setUser(null);
              setIsLogin(false);
              callback();
        });
    };

    // @ts-ignore
    return (
        <AuthContext.Provider value={{ user, isLogin, signIn, signOut }}>
             {children}
        </AuthContext.Provider>);
};

export default AuthProvider;
