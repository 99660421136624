export const lang = {
    'login': '登录',
    'language':'语言',
    'cn':'中文',
    'en':'英文',
    'welcome':'即使在世界上孤单一人，仅凭自然科学的美丽也可以让生活丰富多彩',
    'regTitle':'创建帐户',
    'loginTitle':'登录 Oldtown',
    'email':'电子邮件',
    'emailPlaceholder':'输入电子邮件',
    'phone':'手机号码',
    'phonePlaceholder':'输入手机号码',
    'user_num':'注册用户总数',
    'userNumPlaceholder': '输入注册用户总数',
    'userName': '用户名',
    'userNamePlaceholder': '输入用户名',
    'password': '密码',
    'passwordPlaceholder': '输入密码',
    'regBtn': '创建帐户',
    'loginBtn': '登陆',
    'loginDesc':'无法登录您的帐户？尝试其他方式',
    'loginRegBtn':'创建帐户',
    'about_title':'关于我们',
    'about_context':'了解更多关于维护Old Town的团队、项目启动的方式和原因以及如何参与的信息。',
    'support_title':'技术支持',
    'support_context':'欢迎发邮件至 support@oldtown.ai',
    'legal_title':' 法律和隐私保护',
    'legal_context':'欢迎发邮件至 support@oldtown.ai',
    'contact_title':'联系我们',
    'contact_context':'欢迎发邮件至 support@oldtown.ai',
    'nav_dashboard': '仪表板',
    'nav_virtual_lab':'虚拟实验室',
    'nav_signOut':'退出',
    'nav_profile':'我的资料',
    'tit_physics':'物理',
    'tit_chemistry':'化学',
    'tit_biology':'生物',
    'notification_title':'通知设置',
    'email_notification':'电子邮件通知',
    'new_product':'产品新功能订阅电子邮件',
    'on': '开启',
    'off': '关闭',
    'firstName': '姓',
    'firstNamePlaceholder':'请输入您姓',
    'lastName': '名',
    'lastNamePlaceholder':'请输入您的名',
    'userTypeLabel':'我是高中生 or 大学生',
    'userType0':'高中生',
    'userType1':'大学生',
    'birthLabel': '生日',
    'btnLabel':'提交',
    'password_length_error':'密码最少为6位！',
    'password_error':'您的密码复杂度太低（密码中必须包含大小写字母、数字、特殊字符），请及时修改密码！',
    'email_error':"请输入正确的电子邮箱地址！",
    'phone_error':"请输入正确的手机号码！",
    'username_empty_error':"用户名不能为空！",
    'password_empty_error':"密码不能为空！",
};
