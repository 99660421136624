import { Lang } from "../../utils/common";
import Nav from 'react-bootstrap/Nav';
import './footer.css'
function Footer(props: any){
    return (<div className='footer tds-footer'>
        <nav className='tds-footer-nav'>
            <ul className='tds-footer-meta tds--align_center tds-list tds-list--horizontal tcl-site-footer'>
               <li className='tds-footer-item'>
                   <a href="/about"  title="Copyright OldTown AI 2023" className="tds-link tcl-link">OldTown AI 2023</a>
               </li>
               <li className='tds-footer-item'>
                    <a className='tds-link tcl-link' href="/support"> Support</a>
                </li>
                <li className='tds-footer-item'>
                    <a href="/legal" className='tds-link tcl-link' >Privacy & Legal</a>
                </li>
                <li className='tds-footer-item'>
                    <a href="/contact" className='tds-link tcl-link' > Contact </a>
                </li>
                <li className='tds-footer-item'>
                    <a href="/notifications" className='tds-link tcl-link' > Notifications </a>
                </li>
                <li className='tds-footer-item'>
                    <a href="/subscriptions" className='tds-link tcl-link' > Subscriptions </a>
                </li>
            </ul>
        </nav>
    </div>)
}

export default Footer;
