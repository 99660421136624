import './header.css'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { Lang } from "../../utils/common";
import {AuthContext, AuthContextType} from "../../utils/AuthProvider";
import {useContext} from "react";

const useAuth = () => useContext(AuthContext);


function NavList(props: any){
    let  {isLogin, locale, outLogin, showLoginModel} = props;
    if(isLogin){
        return(<ul id="nav" className="navbar-nav ms-auto">
            <li className="nav-item">
                <a href='/dashboard' className="">{Lang(locale).nav_dashboard}</a>
            </li>
            <li className="nav-item">
                <a href="/virtual_lab" className="">{Lang(locale).nav_virtual_lab}</a>
            </li>
            <li className='nav-item' >
                <a href='/profile'>{Lang(locale).nav_profile}</a>
            </li>
            <li className="nav-item">
                <a className="" onClick={()=> outLogin()}>{Lang(locale).nav_signOut}</a>
            </li>
        </ul>);
    }else{
        return (
            <ul id="nav" className="navbar-nav ms-auto">
                <li className="nav-item">
                    <a className="" onClick={()=>showLoginModel()}>{Lang(locale).login}</a>
                </li>
            </ul>
        );
    }
}

function Header(props: any){
    const auth = useAuth();

    const { user, isLogin, signOut } = auth as AuthContextType;

    const { locale , setLocale, showLoginModel, isIndex }= props;

    const outLogin = ()=>{
        // @ts-ignore
        signOut(()=>{
            localStorage.removeItem('userToken');
            localStorage.removeItem('user');
            window.location.href="/";
        });

    }

    const showNav = ()=>{
        let navbarToggler = document.querySelector("#navbarToggler");
        // @ts-ignore
        if(navbarToggler.classList.contains('active')) navbarToggler.classList.remove('active');
        // @ts-ignore
        else navbarToggler.classList.add('active');
        let navbarSupportedContent = document.querySelector("#navbarSupportedContent");
        // @ts-ignore
        if(navbarSupportedContent.classList.contains('show')) navbarSupportedContent.classList.remove('show');
        else {
            // @ts-ignore
            navbarSupportedContent.classList.add('show');
        }
    }

    const chageLang = (key:string)=>{
        setLocale(key);
    }


    return (
        <div className='header'>
            <div className={isIndex?"navbar-area":"navbar-area sticky"}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <img  src="./images/logo.png" alt="Logo"/>
                                </a>
                                <button className="navbar-toggler" id="navbarToggler"  onClick={()=>showNav()} type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <div className="ms-auto">
                                        <NavList
                                            isLogin={isLogin}
                                            locale={locale}
                                            outLogin={outLogin}
                                            showLoginModel={showLoginModel}
                                        />

                                    </div>
                                </div>
                                <div className="header-btn">
                                    <NavDropdown  title={Lang(locale).language} id="lang-dropdown">
                                        <NavDropdown.Item eventKey="cn" onClick={()=>chageLang("zh-cn")}>{Lang(locale).cn}</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="en" onClick={()=>chageLang("en-us")}>{Lang(locale).en}</NavDropdown.Item>
                                    </NavDropdown>
                                </div>


                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;
