import React from "react";
import './css/index.css';
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons'


const StaffItem = (props: any)=>{
    const { title, desc} = props;
    return (<div className={'staff-item'}>
        {desc!=''?(<p>{ title }</p>):(<p className=' line-height-50'>{ title }</p>)}
        {desc!=''? <p>{ desc }</p>:''}
    </div>)
}

const Staff = ()=>{
    return (<div className='staff'>
        <ul>
            <li> <StaffItem title={'1*10^12'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^9'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^6'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^3'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1 Meter'} desc={''} /> </li>
            <li> <StaffItem title={'1*10^-3'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^-6'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^-9'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^-12'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^-15'} desc={'Millimeter'} /> </li>
            <li> <StaffItem title={'1*10^-19'} desc={'Millimeter'} /> </li>
        </ul>
    </div>);
}

const RangeItemTit = (props: any)=>{
    const { tits } = props;
    return (<div className={'tit'}>
        {tits.map((v:string)=>{
            return (<p>{v}</p>)
        })}
    </div>)
}
const RangeItem = (props: any)=>{
    const { min, max, titles, type,styleObj } = props;
    return (<div className={type=='small'? 'rangeItem small': 'rangeItem'}>
        {max==100?(<div style={{ position:'absolute', top:"0px", height:"60px", width:"100%",textAlign:'center'}} >
            <ArrowUp size={'60'} style={{height:'100%'}} fill={'full'} />
        </div>):null}
        <div className={'box'} style={ styleObj }>
            {titles.map((obj:any)=>{
                return (<p>
                    <RangeItemTit tits={obj} />
                </p>);
            })}
        </div>
        {min==0?(<div style={{ position:'absolute', top:"930px", height:"60px", width:"100%",textAlign:'center'}}>
            <ArrowDown size={'60'} style={{height:'100%'}} fill={'full'} />
        </div>):null}
    </div>);
}



const MainLeft = ()=>{
    // @ts-ignore
    return(<div className={'main-left'}>
        <div className={'rangeContext'}>
            <RangeItem min={0} max={100} styleObj={{position:'absolute',top:'60px',height:'870px',marginBottom:'60px'}} titles={[['力'],['牛顿1','惯性定律'],['牛顿2','F=ma','惯性定律'],['质点','参考系','位移','匀速直线运动'],['力的合成与分解'],['抛体运动'],['圆周运动'],['万有引力'],['Effective','Range','099-199']]} />
            <RangeItem max={100} styleObj={{position:'absolute',top:'60px',height:'870px'}} type={'small'}  titles={[['光'],['最小粒子光子']]} />
            <RangeItem max={100} styleObj={{position:'absolute',top:'60px',height:'870px'}} type={'small'} titles={[['磁'],['电子运动方向']]} />
            <RangeItem  styleObj={{position:'absolute',top:'240px',height:'480px'}} type={'small'} titles={[['声音'],['原子震动']]} />
            <RangeItem min={0} max={100} styleObj={{position:'absolute',top:'60px',height:'870px',marginBottom:'60px'}} titles={[['热能'],['能量'],['重力势能'],['动能'],['机械能','守恒定律']]} />
        </div>
    </div>)
}

const  AtomItem = (props: any)=>{
    const { atoms } = props;
    return (<div className={'atomItem'}>
        {atoms.map((item: any)=>{
            return(<div className={'atomBox'} style={{top:item.top}}>
                {item.isArray==true ? (item.title.map((tit: string)=>{
                    return (<p>{tit}</p>);
                })):item.title}
            </div> )
        })}
    </div>)
}

const MainRight = ()=>{
    return (<div className={'main-right'}>
       <div className={'atomContext'}>
           <AtomItem atoms={[{top:'0px',title:'黑洞'},{top:'90px',title: '恒星'},{top:"180px",title:"行星"},{top:"360px",title:'Metal'},{top:"630px",title: ["原子","0.1-0.5纳米","元素周期表"],isArray:true},{top:"720px",title:"原子核"},{top:"810px",title:"质子"},{top:"900px",title:["负电子","2.8179x10^-15"],isArray: true}]} />
           <AtomItem atoms={[{top:"180px",title:"卫星"},{top:"360px",title:'Onion'},{top:"450px",title: "组织"},{top:"540px",title:["洋葱细胞","1-5 Micrometer"],isArray:true},{top:"630px",title:["分子","0.1-0.5纳米"], isArray:true},{top:"810px",title:"中子"},{top:"900px",title:"夸克"}]} />
           <AtomItem atoms={[{top:"360px",title:'生命体'},{top:"630px",title:["离子","0.1-0.5纳米"], isArray:true},{top:"900px",title:"玻色子"}]} />
           <AtomItem atoms={[{top:"450px",title:'混合物'},{top:"540px",title:"有机物"},{top:"900px",title:"光子"}]} />
           <AtomItem atoms={[{top:"360px",title:['每一个物质','拥有3个基本属性','9个普通属性'],isArray:true},{top:"540px",title:["化合物",'材料科学','拥有8个属性']},{top:"900px",title:["正电子","2.8179x10^-15"],isArray: true}]} />
       </div>
    </div>)
}

class Index extends React.Component{
    constructor(props: {} | Readonly<{}>) {
        // @ts-ignore
        super(props);
    }

    componentDidMount(){

    }

    render() {
        return (<div className='fullscreen main-context' >
             <Staff />
             <MainLeft />
             <MainRight />
        </div>)
    }
}
export default Index;
